import { gql } from '@apollo/client';

const SWITCH_STRIPE_BILLING = gql`query SwitchStripeBilling {
    switchStripeBilling {
    data{
      url 
    }
    messages {
      error
      success
      __typename
    }
    status
    http_code
    __typename
  }
}`;

export default SWITCH_STRIPE_BILLING;