import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as config from '../../settings.json';

import useWindowOnClick from '../../helpers/customHooks/useWindowOnclick';
import GlobalSearchBarWrapper from '../../containers/GlobalSearchBarWrapper';
import InviteUserWrapper from '../../containers/InviteUserWrapper';
import ThemeToggle from './ThemeToggle';
import { toggleSidebarAction } from '../../redux/action/utils';
import { ReduxStateType } from '../../redux/store';
import { CloseSideBar, DownArrowIcon, HamburgerIcon } from '../Global/icons';
import { returnImageURL } from '../Gallery/helpers/returnImageURL';
import CreateScrapIconToggler from '../CreateScrap/components/CreateScrapIconToggler';
import './MainNavigation.css';
import { Link } from 'react-router-dom';
import ScrapSavedContainer from '../../containers/ScrapSavedContainer';
import useOutsideAlerter from '../../helpers/customHooks/useOutsideAlerter';
import { socialLogout } from '../../helpers';
import PublicNavigation from './PublicNavigation';
import NotificationList from "../NotificationList";
const MainNavigation = ({ user, addNewTopic, currentHighlight,load }) => {
  const dispatch = useDispatch();
  const { width } = useSelector(
    (state: ReduxStateType) => state.utils.dimensions
  );

  const expanded = useSelector((state: ReduxStateType) => state.utils.expanded);

  const isUserDefined = Object.keys(user).length > 1;
  const { pathname } = useLocation();
  const parsedUserName = pathname.replace('/', '');

  const [showMenu, setShowMenu] = useState(false);
  const [hide, setHide] = useState(false);
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleOutSideClick = (el: HTMLElement) => {
    const closestElement = el.closest('.main-navigation__profile');
    if (!closestElement) setShowMenu(false);
  };
  const wrapperRef = useRef(null);
  const searchRef = useRef(null);

  const handleLogout = (e) => {
    e.preventDefault();
    socialLogout();
    window.location.href = '/logout';
  };
  useOutsideAlerter(wrapperRef, () => setShowMenu(false), !showMenu, true);
  useOutsideAlerter(searchRef, () => setHide(false), !hide, true);
  useWindowOnClick({
    cb: handleOutSideClick,
    removeListener: !showMenu,
  });
  useWindowOnClick({
    cb: (el) => {
      const closestElement = el.closest('.navigation__toggle');
      if (!closestElement && !el.closest('.main-navigation__link-invite'))
        setShowMobileMenu(false);
    },
    removeListener: !showMobileMenu,
  });
  useWindowOnClick({
    cb: (el) => {
      const closestElement = el.closest('.navigation__toggle');
      if (!closestElement && !el.closest('.main-navigation__link-invite'))
        setShowMobileMenu(false);
    },
    removeListener: !showMobileMenu,
  });

  useEffect(() => {
    if (width <= 768) {
      dispatch(toggleSidebarAction(false));
    } else {
      dispatch(toggleSidebarAction(true));
    }
  }, [width]);
  
  if (!isUserDefined && !load)
    return (
      <PublicNavigation/>
    );


  return (
    <nav className="main-navigation main-nav">
      <div className="main-navigation__links">
        {isUserDefined && !load && (
          <HamburgerIcon
            className={`main-navigation__union${
              expanded ? ' main-navigation__union--active' : ''
            }${
              width > 768 && !expanded
                ? ' main-navigation__union--force-show'
                : ''
            }`}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(toggleSidebarAction(!expanded));
            }}
          />
        )}

        {expanded && !load ? (
          <div className={`sl-main-logo`}>
            <a href="/">
              <img
                src="/dashboard/img/Group.svg"
                alt="Scrappi"
                className="sl-logo"
              />
            </a>
            <button className="sl-close-logo-btn">
              <CloseSideBar
                className="sl-close-logo hoverState-logo"
                onClick={() => {
                  dispatch(toggleSidebarAction(!expanded));
                }}
              />
            </button>
          </div>
        ) : null}

        {!load && (
          <div className="main-navigation__global-search">
            <GlobalSearchBarWrapper />
          </div>
        )}
        {!load && <CreateScrapIconToggler />}
        {!load && <ScrapSavedContainer />}
      </div>

      {isUserDefined && (
        <div
          className={`main-navigation__action-wrapper ${
            showMenu ? 'open' : ''
          }`}
        >
          {/* <div className="main-navigation__search">
            <GlobalSearchBarWrapper />
          </div> */}
          {/* <div className="main-navigation__bell"> */}
          {/* <Link to="/help">
              <Icon iconClass="question-circle" size="big" />
            </Link> */}
          <NotificationList view={'popup'} firstLoad={4} />

          <span
            onClick={(e) => {
              setShowMenu((current) => !current);
            }}
            onKeyDown={() => setShowMenu((current) => !current)}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            role="button"
            tabIndex={0}
            className="main-navigation__profile"
          >
            <div className="main-navigation__user-details">
              <p className="main-navigation__user-name">@{user.userName}</p>
              {/* src={user?.avatar || '/dashboard/img/default-avatar.jpg'} */}
              <DownArrowIcon className="main-navigation__arrow-icon" />
            </div>

            {/* </div> */}
            <div className="main-navigation__avatar">
              <img
                src={
                  user?.avatar
                    ? returnImageURL(user.avatar)
                    : '/dashboard/img/default-avatar.jpg'
                }
                alt="Avatar"
                className="main-navigation__user-img"
              ></img>
            </div>
          </span>
          {showMenu && (
            <div
              className={`main-navigation__popup ${
                parsedUserName === 'profile' || parsedUserName === user.userName
                  ? ' active'
                  : ''
              }`}
              ref={wrapperRef}
            >
              <div className="main-navigation__menu">
                <div className="main-navigation__options-wrapper">
                  <ul>
                    <li>
                      <span className="main-navigation__account">Account</span>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: `/profile`,
                          search: `?my-account=true`,
                        }}
                        className="main-navigation__edit"
                        onClick={() => setShowMenu(false)}
                      >
                        {' '}
                        <span className="main-navigation__edit-text">
                          My Account
                        </span>
                      </Link>
                    </li>
                    {user.can_send_invite && (
                      <li>
                        <button
                          onClick={() => {
                            setShowInviteForm(true);
                            setShowMenu(false);
                          }}
                        >
                          Invite Scrapper
                        </button>
                      </li>
                    )}
                    <li>
                      <Link
                        to={{
                          pathname: `/profile`,
                          search: `?show-subscription=true`,
                        }}
                        onClick={() => {
                          setShowMenu(false);
                        }}
                      >
                        Subscription
                      </Link>
                    </li>

                    {/*<li>*/}
                    {/*  <Link*/}
                    {/*    to={{*/}
                    {/*      pathname: `/profile`,*/}
                    {/*      search: `?show-storage=true`,*/}
                    {/*    }}*/}
                    {/*    onClick={() => {*/}
                    {/*      setShowMenu(false);*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    Subscription*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    <li>
                      <Link to="/help">Support</Link>
                    </li>

                    <li>
                      <a href="/logout" onClick={handleLogout}>
                        Log Out
                      </a>
                    </li>

                    <ThemeToggle />
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <InviteUserWrapper
        close={setShowInviteForm.bind(null, false)}
        controlled={{
          show: showInviteForm,
          setShow: setShowInviteForm,
        }}
      />
    </nav>
  );
};

export default MainNavigation;