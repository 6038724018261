import { gql } from '@apollo/client';

export const GET_USER_STORAGE = gql`
  query userStorage{
    userStorage{ 
      status
      data
      {        
        used
          {
          value
          type
          value_in_bytes
        }
        type
      }
    
    }
      
  }
`;
