import React, { useEffect, useState } from 'react';
import './styles.css';
import { useLazyQuery } from '@apollo/client';

import GET_STRIPE_CHECKOUT from '../../containers/data/getStripeCheckoutForSubscriptionItem';

interface PropTypes {
    name?: string;
    price?: number;
    id?: string;
    isAnnual?: boolean;
}

const PriceCard = ({
    name,
    price,
    id,
    isAnnual
}: PropTypes) => {


    const [openRedirectToStripe, setRedirectToStripe] = useState<boolean>(false);
    const [itemPriceId, setItemPriceId] = useState<string | null>(null);
    const [unitPrice, setUnitPrice] = useState<string | null>(null);

    const [
        fetchSubscriptionItemUrl,
        { data: subscriptionItemUrl, loading: subscriptionItemLoading },
    ] = useLazyQuery(GET_STRIPE_CHECKOUT(), {
        variables: {
            itemPriceId,
            unitPrice
        },
    }    
    );

    const onChangeStripeSubscription = (itemPriceId: string, unitPrice: string) => {
        setRedirectToStripe(true);
        setItemPriceId(itemPriceId);
        setUnitPrice(unitPrice);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (setRedirectToStripe && itemPriceId !== null && unitPrice !== null) {
                await fetchSubscriptionItemUrl({ variables: { itemPriceId, unitPrice } });
                
            }
        };
        fetchData();
    }, [setRedirectToStripe, itemPriceId, unitPrice]);


    return (
        <> 
            {(openRedirectToStripe && subscriptionItemUrl?.getStripeCheckoutForSubscriptionItem?.data?.url) && (
                window.location.replace(subscriptionItemUrl?.getStripeCheckoutForSubscriptionItem?.data?.url)
            )}
           
            <div className="package" onClick={() => onChangeStripeSubscription(id || '', String(price) || '')}>
                {(isAnnual) && (
                    <div className="package__best-deal">Best Deal!</div>
                )}
                <div className='package__name'>{name}</div>
                <div className={`package__price__${isAnnual ? 'annually' : 'monthly'}`}>${price}</div>
                <div className='package__trial'>Get Started</div>                
            </div>
        </>
    );
};

export default PriceCard;