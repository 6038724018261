import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER_STORAGE } from './data/getUserStorage';
import PriceCard from '../components/Pricing/PriceCardStripe';
import GET_STRIPE_PRODUCT_ITEMS from './data/getStripeProducts';
import MANAGE_STRIPE_SUBSCRIPTION from './data/manageStripeSubscription';
import EDIT_STRIPE_PAYMENT from './data/editStripePayment';
import SWITCH_STRIPE_BILLING from './data/switchStripeBilling';
import NutCheckmark from '../components/Global/icons/NutCheckmark';

interface GetStripeProducts {
  id: string;
  name: string;
  price: number;
  isAnnual: boolean;   
}

interface StorageData {
  type: string;
  used: {
    value: number | string;
    type: string;
    value_in_bytes: number | string;
  };
}

const PricingPageContainer = () => {
  // State for toggling between annual and monthly subscriptions
  const [isAnnual, setIsAnnual] = useState(false);
  const [isInPlan, setIsInPlan] = useState(false);
  const [renewalDate, setRenewalDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // State to store the subscription list
  const [stripeProducts, setStripeProducts] = useState<GetStripeProducts[]>();

  const [error, setError] = useState(null);
  const [isLoadingBilling, setIsLoadingBilling] = useState(true);
  const [isLoadingPayment, setIsLoadingPayment] = useState(true);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [isLoadingStorage, setIsLoadingStorage] = useState(true);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  const [step, setStep] = useState(1);

  const { data: dataBilling, loading: loadingBilling } = useQuery(SWITCH_STRIPE_BILLING, { skip: step !== 2, });
  const { data: dataPayment, loading: loadingPayment } = useQuery(EDIT_STRIPE_PAYMENT, { skip: step !== 2, });
  const { data: dataSubscription, loading: loadingSubscription } = useQuery(MANAGE_STRIPE_SUBSCRIPTION);
  const { data: dataStorage, loading: loadingStorage } = useQuery(GET_USER_STORAGE, { skip: step !== 2, });
  const { data: dataProducts, loading: loadingProducts } = useQuery(GET_STRIPE_PRODUCT_ITEMS, { skip: step !== 2, });

  const userStorageDummyData = [
      {
        type: 'file',        
        used: {
          value: '...',
          type: 'GB',
          value_in_bytes: '...'
        },
      },    
    ];  

    const [userStorage, setUserStorage] = useState<StorageData[]>(
      userStorageDummyData
    );
   
    const checkFileType = (type: string) => {
      const typeData = userStorage.filter((f) => f.type === type);
      if (typeData?.length) {
        return `${typeData[0].used.value} ${typeData[0].used.type}`;
      }
      return null;
    };

    const checkExceededFileSize = ({ totalSizeInBytes }, type: string) => {
      //totalSizeInBytes could be 5368709120 (5GB) or 268435456000 (250GB). If you are in the plan but have not canceled, pass in 268435456000.
      //If you are in the plan but have canceled, pass in 5368709120. This returns true if you've exceeded the allowable TotalSize.
      const typeData = userStorage.filter((f) => f.type === type);
      
      if (typeData?.length) {
        return Number(typeData[0].used.value_in_bytes) > totalSizeInBytes ? true : false
      }
    }


  const handleSwitchBilling = async () => {
    try {
        const urlSwitchBilling = dataBilling?.switchStripeBilling?.data.url
        window.location.replace(urlSwitchBilling)
    } catch (error) {
        setError('Failed to redirect to switch billing.');
    }
  };  

  const handleEditPayment = async () => {
    try {
        const urlEditPayment = dataPayment?.editStripePayment?.data.url
        window.location.replace(urlEditPayment)
    } catch (error) {
        setError('Failed to redirect to edit payment.');
    }
};

const handleManageSubscription = async () => {
  try {
      const urlManageSubscription = dataSubscription?.manageStripeSubscription?.data.url
      window.location.replace(urlManageSubscription)
  } catch (error) {
      setError('Failed to redirect to manage subscription.');
  }
};

useEffect(() => {
  if (!loadingProducts && dataProducts) {
    const updatedArray = dataProducts?.getStripeProducts?.data;
    
    setStripeProducts(updatedArray)
    setIsLoadingProducts(false);
  }
}, [dataProducts, loadingProducts, isAnnual, isInPlan]);


useEffect(() => {
    if (!loadingStorage && dataStorage?.userStorage?.data) {
      setUserStorage(dataStorage.userStorage.data);
      setIsLoadingStorage(false);
    }
}, [loadingStorage, dataStorage]);


useEffect(() => {
  if (!loadingSubscription && dataSubscription) {
    const subscription = dataSubscription.manageStripeSubscription.data
    if (subscription.customerActive) {
      setIsInPlan(true);
      setRenewalDate(subscription.renewalDate);
      setEndDate(subscription.endDate);
      if (subscription.isAnnual)
      {
        setIsAnnual(true);
      }
    }         

    setIsLoadingSubscription(false);
    setStep(2); //Need to call into Subscription first to get the customer to create in Stripe
  }
}, [loadingSubscription, dataSubscription]);


useEffect(() => {
  if (!loadingPayment && dataPayment) {
    setIsLoadingPayment(false);
  }
}, [loadingPayment, dataPayment]);


useEffect(() => {
  if (!loadingBilling && dataBilling) {
    setIsLoadingBilling(false);
  }
}, [loadingBilling, dataBilling]);


  const wordToHighlight = '(Pro)';
  const featureItems = ['Unlimited Web Scraps, Notes', 'Unlimited Highlights', 'Unlimited Collections', 'Collaborations on Collections (Pro)', 'Unlimited Share Groups', 'Unlimited Contacts', 'Multiple Images on a Single Scrap (Pro)', 'Unlimited Screenshots', 'Upload Files on Scraps (Pro)', 'Up to 250GB (Pro)'];


  return (
    <>        
      { !loadingBilling && !loadingPayment && !loadingSubscription && !loadingStorage && !loadingProducts && (
        <>       

          <div className="profile-form-storage-plan">
            You are currently on the <span>{isInPlan ? 'Pro Plan' : 'Free Plan'}</span>.
          </div>
          <div className="profile-form-storage">  
            <div className="profile-form-storage-text-group">
              <div className="profile-form-storage-text-group-left">Current usage:</div>
              <div className="profile-form-storage-text-group-right">
                          
              <span className={`${checkExceededFileSize({ totalSizeInBytes: isInPlan ? (endDate !== "" ? 5368709120 : 268435456000) : 5368709120 }, 'file') ? 'storage-exceed' : ''}`}>
                  {checkFileType('file')} / {isInPlan ? '250 GB': '5 GB'}
                </span>
              </div>  
            </div>    
          </div>

          {(isInPlan) && endDate == "" && (   
          <div className="profile-form-storage-renews">
            <div className="profile-form-storage-renews-left">Renews:</div>
            <div className="profile-form-storage-renews-right">{renewalDate}</div>        
          </div>
          )}
          
          {
            isInPlan && endDate !== "" && (
              <>
                <div className="profile-form-storage-ends">
                  <div className="profile-form-storage-ends-left">Ends on:</div>
                  <div className="profile-form-storage-ends-right">{endDate}</div>  
                </div>

                {(checkExceededFileSize({ totalSizeInBytes: 5368709120 }, 'file') ) && (      
                  <div className="profile-form-storage-canceled">
                    <p className="">
                    You are currently over the Free Plan storage limit of 5 GB. 
                    When your account is converted to the Free Plan you will lose data. 
                    Older Scraps will be automatically deleted to bring you back within storage compliance. 
                    It is recommended that you reduce your storage use to avoid losing data you wish to keep.
                  
                    </p>
                  </div>  
                )}       

                <div className="profile-form-storage-canceled"> 
                  <p>
                    Your Pro Plan has been canceled and will be automatically converted to the Free Plan on the date above.
                  </p>
                </div>
              </>
            )
          }

          {(isInPlan) && (    
            <div className="profile-form-storage-manage-subscription">
              {(endDate == "") ? (
                <>
              <a href="#"  onClick={(e) => {
                if (loadingBilling) e.preventDefault(); // Prevent click if link is disabled
                else handleSwitchBilling();
              }} 
              style={{
                color: loadingBilling ? 'var(--color-link-color);' : 'var(--color-link-color);',  // Change link color when disabled
                cursor: loadingBilling ? 'not-allowed' : 'pointer',
              
              }}>
                Switch to {isAnnual ? 'Monthly' : 'Annual'} Billing
              </a>
              |
              <a href="#"  onClick={(e) => {
                if (loadingPayment) e.preventDefault(); // Prevent click if link is disabled
                else handleEditPayment();
              }} 
              style={{
                color: loadingPayment ? 'var(--color-link-color);' : 'var(--color-link-color);',  // Change link color when disabled
                cursor: loadingPayment ? 'not-allowed' : 'pointer',
                
              }}>
                Edit Payment
              </a>  
              </>
              )
              :
              <a href="#"  onClick={(e) => {
                if (loadingSubscription) e.preventDefault(); // Prevent click if link is disabled
                else handleManageSubscription();
              }} 
              style={{
                color: loadingSubscription ? 'var(--color-link-color);' : 'var(--color-link-color);',  // Change link color when disabled
                cursor: loadingSubscription ? 'not-allowed' : 'pointer',
                
              }}>
                Reactivate Pro Plan
              </a>  
              }
              |
              <a href="#"  onClick={(e) => {
                if (loadingSubscription) e.preventDefault(); // Prevent click if link is disabled
                else handleManageSubscription();
              }} 
              style={{
                color: loadingSubscription ? 'var(--color-link-color);' : 'var(--color-link-color);',  // Change link color when disabled
                cursor: loadingSubscription ? 'not-allowed' : 'pointer',
                
              }}>
                Manage Subscription
              </a>
              {error && <div>{error}</div>}
            </div>
          )} 

          {(!isInPlan) && ( 
            <div className='profile-form-storage-upgrade'>
              Upgrade to Pro
            </div> 
          )}

          {(!isInPlan) && (           
            <div className='profile-form-storage-price-wrapper'>
              {stripeProducts && stripeProducts.map(priceData => {
                return <PriceCard
                  name={priceData.name}
                  price={priceData.price}
                  isAnnual={priceData.isAnnual}
                  id={priceData.id}                  
                />
              })}
            </div>
          )}
        </>
      )}

      <div className='profile-form-storage-features'>
      <div className="features-left">
        <div className="features-inside">
          <h3>Features</h3>
          <span className="features-small">Get <span className="features-highlight">Pro</span> for as low as $8.25/mo when you select annual billing</span>
        </div>
      </div>
      <div className="features-right">
        <ul className="features-ul">
            {featureItems.map((item, index) => {
          // Split item into words and highlight the target word
          const parts = item.split(' ').map((word, i) => {
            if (word.includes(wordToHighlight)) {
              return <span key={i} className="features-highlight">{word}</span>;
            }
            return word + ' ';
          });

          return (
            <li className="features-li" key={index}>
              <NutCheckmark /> <span className="features-list">{parts}</span>
            </li>
          );
        })}
        </ul>
      </div>
    </div>

    </>
  )


};

export default PricingPageContainer;
