import { gql } from '@apollo/client';

const MANAGE_STRIPE_SUBSCRIPTION = gql`query ManageStripeSubscription {
    manageStripeSubscription {
    data{
      url
      customerActive
      price
      isAnnual
      renewalDate
      endDate 
    }
    messages {
      error
      success
      __typename
    }
    status
    http_code
    __typename
  }
}`;

export default MANAGE_STRIPE_SUBSCRIPTION;