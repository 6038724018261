import React, { useState, useEffect } from 'react';
import PricingPageContainer from '../../../containers/PricingPageContainer';


const SubscriptionComponent = () => {

    return (

    <PricingPageContainer/>

)};

export default SubscriptionComponent;