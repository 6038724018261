import { gql } from '@apollo/client';

const EDIT_STRIPE_PAYMENT = gql`query EditStripePayment {
    editStripePayment {
    data{
      url 
    }
    messages {
      error
      success
      __typename
    }
    status
    http_code
    __typename
  }
}`;

export default EDIT_STRIPE_PAYMENT;